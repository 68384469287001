.hamburger-button {
    display: flex;
    margin-bottom: 3vh;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: flex-end;
    z-index: 2;
}

.hamburger-button_bar {
    width: 35px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
}

.change .first-bar {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.change .second-bar {
    opacity: 0;
}

.change .third-bar {
    -webkit-transform: rotate(45deg) translate(-7px, -7px);
    transform: rotate(45deg) translate(-7px, -7px);
}

@media only screen and (max-width: 1024px) {
    .hamburger-button {
        margin-right: 5%;
        margin-top: 1vh;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 480px) {
    .hamburger-button {
        margin-bottom: 0;
        margin-right: 0;
        padding-right: 10%;
    }

    .hamburger-button_bar {
        width: 21px;
        height: 2px;
        margin: 5px 0;
        transition: 0.4s;
    }

    .change .first-bar {
        -webkit-transform: rotate(-45deg) translate(-5px, 5px);
        transform: rotate(-45deg) translate(-5px, 5px);
    }

    .change .third-bar {
        -webkit-transform: rotate(45deg) translate(-5px, -5px);
        transform: rotate(45deg) translate(-5px, -5px);
    }
}