.page-paragraph {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    line-height: 1.5;
    padding: 0 10%;
}

.about-link {
    color: #1DA1F2;
    text-decoration: none;
}

.about-link:hover {
    text-decoration: underline;
}

.contacts {
    line-height: 1.7;
}

.no-wrap {
    white-space: nowrap;
}

@media only screen and (max-width: 480px) {
    .page-paragraph {
        font-size: 13.5px;
        text-align: justify;
    }
}

@media only screen and (max-width: 320px) {
    .page-paragraph {
        font-size: 13px;
    }
}