.menu-wrapper {
    position: absolute;
    display: flex;
    opacity: 0;
    justify-content: flex-end;
    background: #000;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    padding: 100px 10%;
    z-index: -1;
}

.hidden {
    opacity: 1;
    transition: 0.5s;
    z-index: 1;
}

.menu-wrapper>nav>ul {
    list-style: none;
}

.menu-wrapper>nav {
    width: 30%;
    text-align: right;
}

.menu-wrapper>nav>ul>li {
    padding: 5px 0;
}

.navigation-link {
    text-decoration: none;
    color: #fff;
}

.navigation-link:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
}

@media only screen and (max-width: 1024px) {
    .menu-wrapper {
        padding: 100px 5%;
    }
}

@media only screen and (max-width: 480px) {
    .menu-wrapper {
        padding: 80px 10%;
    }
}