.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 80%;
    margin: 0 auto;
    padding: 25px 0;
}

@media only screen and (max-width: 1024px) {
    .page-wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    html, body, #root, .app-wrapper {
        height: -webkit-fill-available;
    }

    .page-wrapper {
        padding: 20px 0;
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }
}