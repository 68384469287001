html {
  height: -webkit-fill-available;
}

body {
  text-align: center;
  background: #000;
  color: #fff;
  font-family: 'IBM Plex Mono', monospace;
}

.slick-slider {
  width: 100% !important;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

.slick-slide {
  overflow: hidden !important;
  border: 1px solid #000;
}

.slick-arrow {
  transform: scale(3) !important;
}

.slick-prev {
  transform: scale(3) rotate(180deg) !important;
  left: -35px !important;
}

.slick-next {
  right: -35px !important;
}

.slick-active.slick-current {
  z-index: 10000 !important;
}

.slick-dots li button:before {
  color: #fff !important;
}

@media only screen and (max-width: 480px) {
  .slick-prev {
    left: -21px !important;
  }

  .slick-arrow {
    width: 11px !important;
  }

  .slick-next {
    right: -21px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .slick-slide {
    border: unset;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: -40px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .slick-dots {
    display: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .slick-dots {
    bottom: -35px !important;
  }
}

video {
  max-height: 80vh !important;
  display: block !important;
  object-fit: cover !important;
}