.not-found-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.not-found-wrapper>a {
    margin-top: 10px;
    text-decoration: none;
}

.not-found-wrapper>a:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}

@media only screen and (max-width: 480px) {
    .not-found-wrapper>h1 {
        font-size: 20px;
    }

    html, body, #root, .app-wrapper {
        height: -webkit-fill-available;
    }

    .not-found-wrapper {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }
}