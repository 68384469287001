.video-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
}

.video {
    width: 100%;
    cursor: pointer;
}

.slider-arrow {
    width: 30px;
}